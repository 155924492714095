<template>
  <div class="card-branches">
    
    <div class="name-branches">
      <div class="name">
        <div class="name-branch">
          <p>{{ band_name.text }}</p>
        </div>
      </div>
    </div>

    <div class="path-time">
      <validation-observer  v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(createBand)">
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider name="مقدار الفترة" :rules="rules.pathAmount" v-slot="{ errors }">
                <input-text v-model="pathAmount" :field-errors="errors" :label-top="'مقدار الفترة'" :placeholder="'أكتب هنا مقدار الفترة'"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="مقدار البند" :rules="rules.bndAmount" v-slot="{ errors }">
                <input-text v-model="bndAmount" :field-errors="errors" :label-top="'مقدار البند'" :placeholder="'مقدار البند'"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" style="position: relative">
              <ValidationProvider name="بداية البند" :rules="rules.bndStart" v-slot="{ errors }">
                <select-input v-model="bndStart" :field-errors="errors" :label="'بداية البند '" :name="'مباشر '" :items="booleanBandOptions" item-text="text" item-value="value"/>
              </ValidationProvider>
            </v-col>
            <v-col v-if="bndStart === 'after_duration'"  cols="12" md="6" style="position: relative">
              <ValidationProvider name=" البند" :rules="rules.band" v-slot="{ errors }">
                <select-input v-model="band" :field-errors="errors" :label="' البند '" :name="'البند '" :items="booleanBands" item-text="text" item-value="value"/>
              </ValidationProvider>
            </v-col>
            <v-col  v-if="bndStart === 'after_duration'" cols="12" md="6">
              <ValidationProvider name="مقدار البند المختار" :rules="rules.bndSelectedAmount" v-slot="{ errors }">
                <input-text v-model="bndSelectedAmount" :field-errors="errors" :label-top="'مقدار البند المختار'" :placeholder="'مقدار البند المختار'"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" style="position: relative">
              <ValidationProvider name="التنفيذ" :rules="rules.excute" v-slot="{ errors }">
                <select-input v-model="excute" :field-errors="errors" :label="'التنفيد'" :name="'منفذ'" :items="booleanOptions" item-text="name" item-value="value"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="درجة البند" :rules="rules.bndDegree" v-slot="{ errors }">
                <input-text v-model="bndDegree" :field-errors="errors" :label-top="'درجة البند'" :placeholder="'درجة البند'"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" style="position: relative">
              <ValidationProvider name="حال عدم التنفيذ" :rules="rules.notExcutedWarning" v-slot="{ errors }">
                <select-input item-text="text" item-value="value" v-model="notExcutedWarning" :field-errors="errors"  multiple :label="'حال عدم التنفيذ'" :name="'انذار'" :items="nonImplementationOptions"/>

              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="12" style="position: relative">
              <ValidationProvider name="مقدار التنفيذ البند اليومي" :rules="rules.daily" v-slot="{ errors }">
                <input-text field-type="number" v-model="daily" :field-errors="errors" :label-top="'مقدار التنفيذ البند اليومي'" :placeholder="'مقدار التنفيذ البند اليومي'"/>
              </ValidationProvider>
            </v-col>
          </v-row>
          <div class="text-card">
            <div class="btn-done">
                <v-btn type="submit" block color="#00B5AD" class="mt-3 mb-4" style="color: white;font-size: 15px;height: 45px">حفظ</v-btn>
            </div>
          </div>
        </v-form>
      </validation-observer>
    </div>


  </div>
</template>

<script>
import SelectInput from "@/components/select-input";
import InputText from "@/components/input-text";
import { postMethods } from '@/api/Helper';

export default {
  name: "card-branches",
  components: {
    SelectInput,
    InputText
  },
  props: {
    name: {
    },
    path_id: {
    },
    goalOptions: {
      type: Array,
      default: () => [],
    },
    bonodsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageStudent: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
      pathAmount: null,
      bndAmount: null,
      bndStart: null,
      excute: null,
      bndDegree: null,
      notExcutedWarning: null,
      warning: null,
      band_name: null,
      nextDay: null,
      daily: null,
      band: null,
      band_id: null,
      bndSelectedAmount: null,
      booleanOptions: [
        {
          id: 1,
          name: 'نعم',
          value: "yes",
        },
        {
          id: 2,
          name: 'لا',
          value: "no",
        },
      ],
      nonImplementationOptions: [
        {
          id: 1,
          text: 'انذار',
          value: "yellow_card",
        },
        {
          id: 2,
          text: 'اضافة على مقدار اليوم التانى',
          value: "warining",
        },
        {
          id: 3,
          text: 'لا يوجد',
          value: "nothing",
        },
      ],
      booleanBandOptions: [
        {
          id: 0,
          text: 'مباشر',
          value: "now",
        },
        {
          id: 1,
          text: 'بعد فترة',
          value: "after_duration",
        },
      ],
       booleanBands: [
        {
          id: 1,
          value: 1,
          text: "حفظ"
        },
        {
          id: 2,
          value: 2,
          text: "إتقان"
        },
        {
          id: 3,
          value: 3,
          text: "سماع"
        },
        {
          id: 4,
          value: 4,
          text: "تكرار"
        },
        {
          id: 5,
          value: 5,
          text: "ربط"
        },
        {
          id: 6,
          value: 6,
          text: "مراجعة"
        },
        {
          id: 7,
          value: 7,
          text: "تسميع"
        },
        {
          id: 8,
          value: 8,
          text: "إختبار"
        },
        {
          id: 9,
          value: 9,
          text: "تلاوة"
        },
        {
          id: 10,
          value: 10,
          text: "السرد الأسبوعي"
        },
      ],
      rules: {
        pathAmount: {
          required: true,
        },
        bndAmount: {
          required: true,
        },
        bndStart: {
          required: true,
        },
        excute: {
          required: true,
        },
        bndDegree: {
          required: true,
        },
        notExcutedWarning: {
          required: true,
        },
        band: {
          required_if: this.bndStart ==1,
        },
        bndSelectedAmount: {
          required_if: this.bndStart ==1,
        },
        warning: {
          required: true,
        },
        nextDay: {
          required: true,
        },
        daily: {
          required: true,
        },
      }
    }
  },
  methods: {
    async createBand() {
      let formData = new FormData();
      formData.append("path_id", this.path_id);
      formData.append("band_type_id", this.name);
      formData.append("duration_id", this.pathAmount);
      formData.append("band_value", this.bndAmount);
      formData.append("band_start", this.bndStart);
      formData.append("active_band_id", this.band);
      formData.append("band_grade", this.bndSelectedAmount);                                                                                                                                                                                   
      formData.append("after_band_value_done", this.bndSelectedAmount);
      formData.append("not_implemented", this.notExcutedWarning);
      formData.append("daily_band_value", this.daily);
      console.log(formData);
      try {
        let res;
        if (this.band_id == null) {
          res = await postMethods('bands', formData);
        } else {
          res = await postMethods('bands/updatebands', formData);
        }
        if (res.data.status == 1 && res.data.code == 200) {
          this.$toast.success('تم الحفظ بنجاح');
          this.band_id = res.data.data;
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }
    },
    getBonods() {
     this.band_name = this.booleanBands[parseInt(this.name)-1];
    }
  },
  created() {
    this.getBonods();
  }
}
</script>


<style lang="scss" scoped>
.card-branches {
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  padding: 20px 10px 0 10px;

  .name-branches {
    display: flex;
    justify-content: right;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);

    .image {
      width: 22%;
      padding-top: 6px;

      img {
        width: 100%;
      }
    }

    .name {
      width: 100%;
      position: relative;
      margin-right: 10px;

      .name-branch {
        display: flex;
        align-items: center;

        p {
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        i {
          background-color: #00B5AD;
          margin-right: 13px;
          width: 21px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 11px;
          font-weight: 300;
          color: white;
        }
      }

      //p {
      //  font-size: 15px;
      //  font-weight: bold;
      //  margin-bottom: 0;
      //}

      span {
        font-size: 13px;
        color: #B4B4B4;
      }
    }
  }

  .text-card {
    p {
      color: #B4B4B4;
      font-size: 14px;
      line-height: 2em;
    }
  }

  .avatar-branch {
    display: flex;
    align-items: center;

    span {
      margin-right: -3%;
      width: 27px;
      height: 27px;
      border-radius: 50%;

      img {
        width: 100%;
      }
    }

    span:first-child {
      margin-right: 0;
    }

    span.icon-plus {
      background-color: #1FD0A3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: white;
      border: 2px solid white;

      i {
        margin-top: 2px;
      }
    }

  }

  .status-student {
    position: absolute;
    left: 2%;
    top: -2%;
    background-color: #DBF7F0;
    text-align: center;
    color: #1FD0A3;
    font-size: 11px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
  }


  .path-time {
    display: flex;
    justify-content: space-around;
    padding: 15px 10px;


    .col {
      padding-right: 2px;
      padding-left: 2px
    }

    .path {
      display: flex;

      .image {
        background-color: rgba(56, 91, 114, 0.2);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 45%;
        }
      }

      .text-path {
        margin-right: 8px;

        p {
          margin-bottom: 0;
          color: #0F1222;
          font-size: 14px;
          font-weight: bold;
        }

        span {
          color: #B4B4B4;
          font-size: 13px;
        }
      }
    }
  }

  .last-of-type {
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);
  }


  .edit-delete {
    display: flex;
    justify-content: space-around;
    color: #B4B4B4;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer !important;

    .edit, .delete {
      padding-top: 20px;
      padding-bottom: 25px;

    }

    .delete {
      i {
        padding-left: 3px;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      background-color: transparent !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: #B4B4B4 !important;
      cursor: pointer !important;
    }

    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: transparent !important;
    }

    i {
      font-size: 15px;
      margin-right: 10px;
      margin-top: 5px;
      background-color: #FAFCFE;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #D2D5E1;
    }

    i.fa-pencil {
      padding-left: 5px;
    }

    .border {
      border-right: 1px solid rgba(143, 146, 161, 0.26);
      padding: 0;
    }

    span {
      font-size: 15px;
    }

  }


}
</style>
<style lang="scss">
.card-branches {
  .v-btn:not(.v-btn--round).v-size--default {
    background-color: #00B5AD !important;
    font-size: 14px !important;
    color: white;
    border-radius: 5px !important;
  }

}

</style>
